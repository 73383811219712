import React, { useState, useEffect } from 'react';
import '../styles.css';  // Assuming you have the CSS in a separate file

const Standings = () => {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://statsapi.mlb.com/api/v1/standings?leagueId=103,104')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setStandings(data.records);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching standings:', error);
        setError('Error fetching standings from MLB');
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading Standings...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const getRowClass = (team) => {
    if (team.gamesBack === "-") {
      return "first-place";
    }
    if (team.wildCardGamesBack === "-" || team.wildCardGamesBack.includes("+")) {
      return "wild-card";
    }
    return "";
  };

  const renderTeamRow = (team) => (
    <tr key={team.team.id} className={getRowClass(team)}>
      <td data-label="Team"><span>{team.team.name}</span></td>
      <td data-label="W"><span>{team.leagueRecord.wins}</span></td>
      <td data-label="L"><span>{team.leagueRecord.losses}</span></td>
      <td data-label="PCT"><span>{team.leagueRecord.pct}</span></td>
      <td data-label="GB"><span>{team.gamesBack}</span></td>
      <td data-label="Elim #"><span>{team.eliminationNumber === 'E' ? 'Eliminated' : team.eliminationNumber || 'N/A'}</span></td>
      <td data-label="WC GB"><span>{team.wildCardGamesBack}</span></td>
      <td data-label="WC Elim #"><span>{team.eliminationNumberSport === 'E' ? 'Eliminated' : (team.wildCardGamesBack !== "-" && !team.wildCardGamesBack.includes("+") ? team.eliminationNumberSport : "-") || 'N/A'}</span></td>
    </tr>
  );

  const renderDivisionStandings = (division) => (
    <div className="division" key={division.division.id}>
      <h4>{division.division.name}</h4>
      <table>
        <thead>
          <tr>
            <th>Team</th>
            <th>W</th>
            <th>L</th>
            <th>PCT</th>
            <th>GB</th>
            <th>Elim #</th>
            <th>WC GB</th>
            <th>WC Elim #</th>
          </tr>
        </thead>
        <tbody>
          {division.teamRecords.map(renderTeamRow)}
        </tbody>
      </table>
    </div>
  );

  const renderLeagueStandings = (leagueId, leagueName) => {
    const leagueStandings = standings.filter(division => division.league.id === leagueId);
    return (
      <div className="league" key={leagueId}>
        <h3>{leagueName}</h3>
        {leagueStandings.map(renderDivisionStandings)}
      </div>
    );
  };

  return (
    <div className="standings">
      <h2>MLB Standings</h2>
      {renderLeagueStandings(104, 'National League')}
      {renderLeagueStandings(103, 'American League')}
    </div>
  );
};

export default Standings;
