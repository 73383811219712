import React, { useState, useEffect } from 'react';
import BoxScore from './BoxScore';
import CurrentPlay from './CurrentPlay';
import Standings from './Standings';

function MlbAPIIntegrations() {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [activeTab, setActiveTab] = useState('latestGames');
  const [selectedGameFeed, setSelectedGameFeed] = useState(null);
  const [selectedGame, setSelectedGame] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      fetch('https://statsapi.mlb.com/api/v1/schedule?sportId=1')
        .then(response => response.json())
        .then(data => {
          if (data && data.dates && data.dates.length > 0) {
            setGames(data.dates[0].games);
          }
          setLoading(false);
          setLastUpdated(new Date().toLocaleString());
        })
        .catch(error => {
          console.error('Error fetching the MLB schedule:', error);
          setLoading(false);
        });
    };

    fetchData(); 
    const intervalId = setInterval(fetchData, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const handleGameClick = (game) => {
    const liveFeedUrl = `https://statsapi.mlb.com${game.link}`;
    fetch(liveFeedUrl)
      .then(response => response.json())
      .then(data => {
        setSelectedGameFeed(data);
        setSelectedGame(game);
      })
      .catch(error => {
        console.error('Error fetching the game feed:', error);
      });
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'latestGames':
        return (
          <div className="mlb-schedule">        
            <div className="games-container">
              {games.map((game) => (
                <div 
                  key={game.gamePk} 
                  className="game-box" 
                  onClick={() => handleGameClick(game)} 
                  style={{ cursor: 'pointer' }}
                >
                  <h3>
                    <span style={{ fontWeight: 'bold' }}>
                      {game.teams.away.team.name} ({game.teams.away.leagueRecord.wins}-{game.teams.away.leagueRecord.losses})
                    </span> 
                    {" "} @ {" "}
                    <span style={{ fontWeight: 'bold' }}>
                      {game.teams.home.team.name} ({game.teams.home.leagueRecord.wins}-{game.teams.home.leagueRecord.losses})
                    </span>
                  </h3>
                  <p><strong>Date & Time:</strong> {new Date(game.gameDate).toLocaleString()}</p>
                  <p><strong>Venue:</strong> {game.venue.name}</p>
                  <p><strong>Status:</strong> {game.status.detailedState}</p>
                  {game.status.detailedState === 'In Progress' && (
                    <p><strong>Score:</strong> {game.teams.away.team.name} {game.teams.away.score} - {game.teams.home.team.name} {game.teams.home.score}</p>
                  )}
                </div>
              ))}
            </div>
            
            <div className="total-games">
              <h3>Total Games: {games.length}</h3>
            </div>
            
            {lastUpdated && (
              <div className="last-updated">
                <p>Last Updated: {lastUpdated}</p>
              </div>
            )}

            {selectedGame && selectedGameFeed && (
              <div className="game-details">
                <BoxScore feed={selectedGameFeed} />
                <CurrentPlay feed={selectedGameFeed} />
              </div>
            )}
          </div>
        );
      case 'standings':
        return <Standings />;
      case 'statLeaders':
        return <div>MLB Stat Leaders Data...</div>;
      case 'attendance':
        return <div>Highest Season Attendance Data...</div>;
      default:
        return null;
    }
  };

  return (
    <section className="skills">
      <h2>MLB Integrations</h2>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        All stats live from <a href="https://statsapi.mlb.com">https://statsapi.mlb.com</a>
      </div>
      <div className="nav-tabs">
        <button
          className={activeTab === 'latestGames' ? 'tab active' : 'tab'}
          onClick={() => setActiveTab('latestGames')}
        >
          Latest Games
        </button>
        <button
          className={activeTab === 'standings' ? 'tab active' : 'tab'}
          onClick={() => setActiveTab('standings')}
        >
          Current MLB Standings
        </button>
      </div>
      <div className="tab-content">
        {renderContent()}
      </div>
    </section>
  );
}

export default MlbAPIIntegrations;
