import React from 'react';

const BoxScore = ({ feed }) => {
  if (!feed || !feed.liveData || !feed.liveData.boxscore) return null;

  const { teams } = feed.liveData.boxscore;
  const homeTeam = teams?.home;
  const awayTeam = teams?.away;

  if (!homeTeam || !awayTeam) return null;

  return (
    <div className="box-score">
      <h3>Box Score</h3>
      <table>
        <thead>
          <tr>
            <th>Team</th>
            <th>R</th>
            <th>H</th>
            <th>E</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{awayTeam.team?.name || 'N/A'}</td>
            <td>{awayTeam.teamStats?.batting?.runs ?? 'N/A'}</td>
            <td>{awayTeam.teamStats?.batting?.hits ?? 'N/A'}</td>
            <td>{awayTeam.teamStats?.fielding?.errors ?? 'N/A'}</td>
          </tr>
          <tr>
            <td>{homeTeam.team?.name || 'N/A'}</td>
            <td>{homeTeam.teamStats?.batting?.runs ?? 'N/A'}</td>
            <td>{homeTeam.teamStats?.batting?.hits ?? 'N/A'}</td>
            <td>{homeTeam.teamStats?.fielding?.errors ?? 'N/A'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BoxScore;
