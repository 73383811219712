import React from 'react';

function Skills() {
  return (
    <section className="skills">
      <h2>Skills & Technologies</h2>
      <div className="skills-grid">
        <div className="skill-card">Customer Communications Platforms</div>
        <div className="skill-card">Marketing Automation</div>
        <div className="skill-card">Lead Management Systems</div>
        <div className="skill-card">Event Management Integration</div>
        <div className="skill-card">Middleware Administration & Architecture</div>
        <div className="skill-card">Cloud Solutions (Azure, AWS)</div>
        <div className="skill-card">API Management & Middleware Solutions</div>
      </div>
    </section>
  );
}

export default Skills;
