import React from 'react';

function Projects() {
  return (
    <section className="projects">
      <h2>Featured Projects</h2>
      <div className="project-grid">
        <div className="project-card">
          <h3>Customer Communications Hub</h3>
          <p>
            Integrated a multi-channel communications platform to centralize customer interactions 
            and ensure consistent messaging.
          </p>
        </div>
        <div className="project-card">
          <h3>Preference Management System</h3>
          <p>
            Developed a comprehensive preference management system allowing customers to easily 
            control their communication preferences, improving engagement.
          </p>
        </div>
        <div className="project-card">
          <h3>Middleware Administration & Architecture</h3>
          <p>
            Managed and optimized middleware environments and designed architectures that support enterprise-level integrations, 
            providing a robust foundation for business-critical applications.
          </p>
        </div>
        <div className="project-card">
          <h3>Cloud Migration & Solutions</h3>
          <p>
            Led cloud migration projects, moving legacy systems to modern cloud infrastructures while minimizing downtime 
            and enhancing system performance.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Projects;
