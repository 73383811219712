import React from 'react';

function About() {
  return (
    <section className="about">
      <h2>About Me</h2>
      <p>
        Welcome to Dave-Ops.
        </p><p>I'm a Lead Software Engineer specializing in Martech Integrations, Middleware Administration & Architecture, and Cloud Solutions.
        <p>My expertise extends into various cloud platforms, where I focus on building scalable and efficient systems that seamlessly integrate 
        across systems and technologies.
        </p>
      </p>
    </section>
  );
}

export default About;
