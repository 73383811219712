import React from 'react';

const CurrentPlay = ({ feed }) => {
  if (!feed || !feed.liveData || !feed.liveData.plays || !feed.liveData.plays.currentPlay || !feed.liveData.boxscore) return null;

  const currentPlay = feed.liveData.plays.currentPlay;
  const gameStatus = feed.gameData.status.abstractGameState;
  const isPregame = gameStatus === "Preview";

  const homeTeam = feed.gameData.teams.home;
  const awayTeam = feed.gameData.teams.away;

  const batter = currentPlay.matchup?.batter;
  const pitcher = currentPlay.matchup?.pitcher;

  const isHomeTeamBatting = currentPlay.about.halfInning === 'bottom';

  const batterData = batter ? feed.liveData.boxscore.teams[isHomeTeamBatting ? 'home' : 'away'].players[`ID${batter.id}`] : null;
  const pitcherData = pitcher ? feed.liveData.boxscore.teams[isHomeTeamBatting ? 'away' : 'home'].players[`ID${pitcher.id}`] : null;

  const homeLineup = feed.liveData.boxscore.teams.home.battingOrder.map(playerId => feed.liveData.boxscore.teams.home.players[`ID${playerId}`]);
  const awayLineup = feed.liveData.boxscore.teams.away.battingOrder.map(playerId => feed.liveData.boxscore.teams.away.players[`ID${playerId}`]);

  return (
    <div className="current-play">
      <h3>{isPregame ? "Pre-Game" : `Inning ${currentPlay.about.inning} - ${currentPlay.about.halfInning.toUpperCase()}`}</h3>
      {batterData && (
        <div className="current-batter">
          <h4>Current Batter</h4>
          <p>{batterData.person.fullName}</p>
          <p>Position: {batterData.position.name}</p>
        </div>
      )}
      {pitcherData && (
        <div className="current-pitcher">
          <h4>Current Pitcher</h4>
          <p>{pitcherData.person.fullName}</p>
          <p>Position: {pitcherData.position.name}</p>
        </div>
      )}
      <div className="lineups">
        <div className="away-lineup">
          <h4>{awayTeam.name} Batting Order</h4>
          <ul>
            {awayLineup.map(player => (
              <li key={player.person.id}>
                {player.person.fullName} - {player.position.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="home-lineup">
          <h4>{homeTeam.name} Batting Order</h4>
          <ul>
            {homeLineup.map(player => (
              <li key={player.person.id}>
                {player.person.fullName} - {player.position.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CurrentPlay;
