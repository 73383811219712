import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import './styles.css';
import About from './components/About'; // Assuming About is in a separate file
import Skills from './components/Skills'; // Assuming Skills is in a separate file
import Projects from './components/Projects'; // Assuming Projects is in a separate file
import MlbAPIIntegrations from './components/MlbAPIIntegrations'; // Importing the new SportIntegrations component

function DaveOps() {
  return (
    <div className="container">
      
      <header className="header">
        <div className="overlay">
          <h1 className="title">DAVE-OPS</h1>
          <p className="subtitle">Martech, Integrations & Cloud Solutions</p>
        </div>
      </header>

      <nav className="nav-tabs">
        <NavLink exact="true" to="/" className={({ isActive }) => isActive ? "tab active" : "tab"}>About Me</NavLink>
        <NavLink to="/skills" className={({ isActive }) => isActive ? "tab active" : "tab"}>Skills & Technologies</NavLink>
        <NavLink to="/projects" className={({ isActive }) => isActive ? "tab active" : "tab"}>Featured Projects</NavLink>
        <NavLink to="/mlb-integrations" className={({ isActive }) => isActive ? "tab active" : "tab"}>MLB Integrations</NavLink>
      </nav>

      <Routes>
        <Route path="/" element={<About />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/mlb-integrations" element={<MlbAPIIntegrations />} />
      </Routes>

      <footer className="footer">
        <p>© 2024 dave-ops. All rights reserved.</p>
        <p>
          Connect with me on <a href="https://www.linkedin.com/in/david-marusiak-a899a8198/">LinkedIn</a>
        </p>
      </footer>
    </div>
  );
}

ReactDOM.render(
  <Router>
    <DaveOps />
  </Router>,
  document.getElementById('root')
);
